import { PAGINATION_MAX_DISPLAYED_PAGES } from "~/config/item-list";

export const generatePaginationNumbers = (currentPage: number, totalPages: number) => {
  const pages = [];
  const limit = PAGINATION_MAX_DISPLAYED_PAGES;

  if (totalPages <= limit + 2) {
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
  } else {
    pages.push(1);
    if (currentPage > limit + 1) pages.push("...");

    const leftLimit = Math.max(2, currentPage - limit);
    const rightLimit = Math.min(totalPages - 1, currentPage + limit);

    for (let i = leftLimit; i <= rightLimit; i++) {
      pages.push(i);
    }

    if (currentPage < totalPages - limit) pages.push("...");
    pages.push(totalPages);
  }

  return pages;
};
