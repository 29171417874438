export const PAGINATION_MAX_DISPLAYED_PAGES = 3;

export const PRODUCTS_SORTING_OPTIONS = [
  { label: "Prix (croissant)", value: "price_ASC" },
  { label: "Prix (décroissant)", value: "price_DESC" },
  { label: "Puissance (croissante)", value: "c_puissance_ASC" },
  { label: "Puissance (décroissante)", value: "c_puissance_DESC" },
  { label: "Nom (croissant)", value: "title_ASC" },
  { label: "Nom (décroissant)", value: "title_DESC" },
];
export const DEFAULT_PRODUCTS_SORTING_OPTION = {
  "modules-photovoltaiques": "c_puissance_ASC",
  "onduleurs-solaires": "c_puissance_DESC",
  default: "title_ASC",
};

export const RANGES_SORTING_OPTIONS = [
  { label: "Nom (croissant)", value: "title_ASC" },
  { label: "Nom (décroissant)", value: "title_DESC" },
];
export const DEFAULT_RANGES_SORTING_OPTION = "title_ASC";

export const ITEMS_PER_PAGE_OPTIONS = [
  { label: "12", value: "12" },
  { label: "24", value: "24" },
  { label: "36", value: "36" },
  { label: "48", value: "48" },
  { label: "60", value: "60" },
  { label: "72", value: "72" },
  { label: "84", value: "84" },
  { label: "96", value: "96" },
];
export const DEFAULT_ITEMS_PER_PAGE_OPTION = "24";
