import { useLocation, useSearchParams } from "@remix-run/react";

export const getPaginationFromSearchParams = ({
  totalItems,
  itemsPerPageFallback,
  searchParams,
}: {
  totalItems: number | null;
  itemsPerPageFallback: number | null;
  searchParams: URLSearchParams;
}) => {
  const currentPage = parseInt(searchParams.get("page") ?? "1");
  const itemsPerPage = parseInt(
    searchParams.get("items-per-page") ?? itemsPerPageFallback?.toString() ?? "30"
  );
  const totalPages = totalItems ? Math.ceil(totalItems / itemsPerPage) : 1;

  const previousPage = currentPage - 1;
  const nextPage = currentPage + 1;

  return {
    currentPage,
    previousPage: previousPage > 0 ? previousPage : null,
    nextPage: nextPage <= totalPages ? nextPage : null,
    totalPages,
  };
};

export const usePagination = ({
  totalItems,
  itemsPerPageFallback,
}: {
  totalItems: number | null;
  itemsPerPageFallback: number | null;
}) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { currentPage, previousPage, nextPage, totalPages } = getPaginationFromSearchParams({
    totalItems,
    itemsPerPageFallback,
    searchParams,
  });

  const getPaginatedUrlWithCurrentParams = (pageNumber: number) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (pageNumber === 1) {
      newSearchParams.delete("page");
    } else {
      newSearchParams.set("page", pageNumber.toString());
    }
    return `${location.pathname}?${newSearchParams.toString()}`;
  };

  const handlePageChange = (pageNumber: number) => {
    setSearchParams(
      (searchParams) => {
        searchParams.set("page", pageNumber.toString());
        return searchParams;
      },
      { preventScrollReset: false, replace: true }
    );
  };

  const updateItemsPerPage = (itemsPerPageNumber: number) => {
    setSearchParams(
      (searchParams) => {
        searchParams.set("items-per-page", itemsPerPageNumber.toString());
        return searchParams;
      },
      { preventScrollReset: false, replace: true }
    );
  };

  return {
    currentPage,
    previousPage,
    nextPage,
    totalPages,
    getPaginatedUrlWithCurrentParams,
    handlePageChange,
    updateItemsPerPage,
  };
};
